<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-04-23 10:08:09
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-07-28 17:40:20
-->
<template>
  <el-table  :data="reconciliationList" border  :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
    <el-table-column prop="stationName" label="购液企业名称" ></el-table-column>
    <el-table-column prop="unifiedSocialCode" label="统一社会代码" ></el-table-column>
    <el-table-column prop="adminName" label="管理员" ></el-table-column>
    <el-table-column prop="adminPhone" label="账号" ></el-table-column>
    <el-table-column prop="address" label="配送地" ></el-table-column>

    <el-table-column label="创建时间" prop="createDate" width="170px">
          <template slot-scope="scope">
            {{scope.row.createDate}}
          </template>
    </el-table-column>
    <el-table-column label="操作" width="200px">
      <template slot-scope="scope">
        <!-- <invoice-edit :role-id="scope.row.id" @role-list="updateRoleList"/> -->
        <purchase-edit :role-id="scope.row" @plan-list="updateRoleList" />
        <purchase-remove :row-id="scope.row.stationId" @plan-list="updateRoleList"/>
        <!-- <el-button type="text" @click="detailsPage">关闭</el-button> -->
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
// import InvoiceEdit from './TransportEdit'
import PurchaseEdit from './PurchaseEdit'
import PurchaseRemove from './PurchaseRemove.vue'


  export default {
    name: "PurchaseTable",
    props: {
      reconciliationList: {
        type: Array,
        default() {
          return []
        }
      }
    },
    components: {
        PurchaseEdit,
        PurchaseRemove,
    },
    methods: {

      detailsPage() {
        this.$router.replace('/pages/biz/gas_factory/finance/bill/unapplied')
      },
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList() {
        this.$emit('plan-list');
      }
    }
  }
</script>

<style scoped>

</style>
